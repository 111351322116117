import React from "react";
import { Form, Field } from "react-final-form";
import {
  InputAdornment,
  TextField,
  Button,
  ButtonGroup,
  Tooltip,
  Theme,
  createStyles,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      width: "30em",
    },
  })
);

interface IValueCommand {
  onSubmit: (value: { commandId: string; value: string }) => void;
  opts: { label: string; value: number }[];
  commandId: string;
  title?: string;
  currentValue?: number;
}

type InitialValues = {
  int?: number;
};

const SelectCommand: React.FC<IValueCommand> = ({
  currentValue,
  commandId,
  onSubmit,
  title,
  opts,
}) => {
  const initialValues: InitialValues = {};
  const classes = useStyles();
  const state = opts.find((opt) => opt.value === currentValue);
  return (
    <Form
      initialValues={initialValues}
      onSubmit={(values, form) => {
        onSubmit({ commandId, value: values.int });
        setTimeout(form.reset);
      }}
      render={({ handleSubmit, submitting, pristine }) => {
        return (
          <React.Fragment>
            <Typography>
              Stav:
              {state ? ` ${state.label}` : " Neznámý"}
            </Typography>
            <form onSubmit={handleSubmit}>
              <Field
                name="int"
                render={({ input, meta }) => (
                  <ButtonGroup disableElevation>
                    <Tooltip title={commandId}>
                      <Select
                        className={classes.textField}
                        {...input}
                        {...meta}
                        disabled={submitting}
                        label={title || commandId}
                        variant="outlined"
                      >
                        {opts.map((opt) => (
                          <MenuItem value={opt.value}>{opt.label}</MenuItem>
                        ))}
                      </Select>
                    </Tooltip>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={submitting || pristine}
                    >
                      Nastav
                    </Button>
                  </ButtonGroup>
                )}
              />
            </form>
          </React.Fragment>
        );
      }}
    />
  );
};

export default SelectCommand;
