import React, { useEffect, useMemo, useState } from "react";
import { ApolloClient } from "apollo-client";
import { ApolloProvider } from "@apollo/react-hooks";
import { split, ApolloLink } from "apollo-link";
import { WebSocketLink } from "apollo-link-ws";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { useAuth0 } from "@auth0/auth0-react";
import { getMainDefinition } from "@apollo/client/utilities";
import { InMemoryCache } from "apollo-cache-inmemory";

const getWsProtocol = (protocol: string) => {
  console.log(protocol);
  if (protocol === "http:") {
    return "ws";
  }
  return "wss";
};

const wsUri = process.env.REACT_APP_WS_URI || window.location.host;
const apiUri = process.env.REACT_APP_API_URI || window.location.origin;

const WithApollo: React.FC<{ token?: string | null }> = ({
  children,
  token,
}) => {
  const client = useMemo(() => {
    const wsLink = new WebSocketLink({
      uri: `${getWsProtocol(window.location.protocol)}://${wsUri}/graphql`,
      options: token
        ? {
            reconnect: true,
            lazy: true,
            connectionParams: () => ({
              Authorization: `Bearer ${token}`,
            }),
          }
        : {
            reconnect: true,
          },
    });

    const httpLink = createHttpLink({
      uri: `${apiUri}/graphql`,
    });

    const link = split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      httpLink
    );

    const authLink = setContext((_, { headers: prev }) => {
      const headers = { ...prev };
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      return { headers };
    });

    return new ApolloClient({
      link: ApolloLink.from([
        authLink,
        onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors)
            graphQLErrors.forEach(({ message, locations, path }) =>
              // eslint-disable-next-line no-console
              console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
              )
            );
          // eslint-disable-next-line no-console
          if (networkError) console.log(`[Network error]: ${networkError}`);
          // eslint-disable-next-line no-console
          if (networkError) console.dir(networkError);
        }),
        link,
      ]),
      cache: new InMemoryCache(),
    });
  }, [token]);

  return (
    <React.Fragment>
      {token ? (
        <ApolloProvider client={client as any}>{children}</ApolloProvider>
      ) : (
        <div>{children}</div>
      )}
    </React.Fragment>
  );
};

export default WithApollo;
