import { Commands } from "../Screen";
import { DeviceStateQuery } from "../../__generated__/DeviceStateQuery";

type Values = {
  addressId: string;
  value: number;
};

export const commandToValues = (data?: DeviceStateQuery) => (
  item: Commands
) => {
  if (item.type === "float") {
    return {
      ...item,
      currentValue: data?.device?.float.find((val) => val.addressId === item.id)
        ?.value,
    };
  }
  if (item.type === "int") {
    return {
      ...item,
      currentValue: data?.device?.int.find((val) => val.addressId === item.id)
        ?.value,
    };
  }

  if (item.type === "pulseButtonCommand") {
    return {
      ...item,
      currentValue: data?.device?.int.find((val) => val.addressId === item.id)
        ?.value,
    };
  }

  if (item.type === "select") {
    return {
      ...item,
      currentValue: data?.device?.int.find((val) => val.addressId === item.id)
        ?.value,
    };
  }

  return item;
};
