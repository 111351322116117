import { gql } from "@apollo/client";

export const deviceStateQuery = gql`
  query DeviceStateQuery($deviceId: String!) {
    device(input: { deviceId: $deviceId }) {
      timestamp
      int {
        addressId
        value
      }
      float {
        addressId
        value
      }
    }
  }
`;

export const setFloatCommandMutation = gql`
  mutation SetFloatCommand($input: FloatCommandInput!) {
    setFloatCommand(input: $input) {
      commandId
      deviceId
      value
    }
  }
`;

export const setIntCommandMutation = gql`
  mutation SetIntCommand($input: IntCommandInput!) {
    setIntCommand(input: $input) {
      commandId
      deviceId
      value
    }
  }
`;

export const setPulseCommandMutation = gql`
  mutation SetPulseCommand($input: PulseCommandInput!) {
    setPulseCommand(input: $input) {
      commandId
      deviceId
      value
    }
  }
`;

export const deviceUpdateSubscription = gql`
  subscription DeviceUpdateSub($deviceId: String!) {
    deviceUpdates(input: { deviceId: $deviceId }) {
      deviceId
      timestamp
    }
  }
`;
