import { Divider, Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import ValueCommand from "../elements/ValueCommand";
import { makeStyles } from "@material-ui/core/styles";
import Title from "../elements/Title";
import ButtonCommand from "../elements/ButtonCommand";
import SelectCommand from "../elements/SelectCommand";

interface Command {
  id: string;
  title?: string;
  type: string;
}

interface Spacing {
  title: string;
  type: "subtitle";
}

interface Divider {
  type: "divider";
}

interface FloatCommand extends Command {
  type: "float";
  min?: number;
  max?: number;
  unit?: string;
  currentValue?: number;
}

interface IntCommand extends Command {
  type: "int";
  unit?: string;
  currentValue?: number;
}

interface SelectCommand extends Command {
  type: "select";
  currentValue?: number;
  opts: { label: string; value: number }[];
}

interface IButtonCommand extends Command {
  type: "pulseButtonCommand";
  color?: "red" | "green";
  commandValue: number;
  currentValue?: number;
}
export type ValueCommandType = { value: number; commandId: string };
export type Commands =
  | FloatCommand
  | IButtonCommand
  | Spacing
  | Divider
  | IntCommand
  | SelectCommand;

export interface IScreen {
  screenTitle?: string;
  screenTimestamp?: string;
  onFloatCommand: (input: ValueCommandType) => void;
  onIntCommand: (input: ValueCommandType) => void;
  onPulseCommand: (input: ValueCommandType) => void;
  commandQueue: string;
  commands: Array<Commands>;
}

export const usePaperStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

const Screen: React.FC<IScreen> = ({
  commands,
  screenTitle,
  screenTimestamp,
  onFloatCommand,
  onIntCommand,
  onPulseCommand,
}) => {
  const classes = usePaperStyles();
  return (
    <Paper className={classes.paper} variant="outlined">
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Title>{screenTitle}</Title>
        </Grid>
        <Grid item xs={4}>
          <Title>{screenTimestamp}</Title>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {commands.map((command, index) => {
          switch (command.type) {
            case "float":
              return (
                <Grid item xs={12} key={index}>
                  <ValueCommand
                    onSubmit={(value) =>
                      onFloatCommand({
                        commandId: command.id,
                        value: parseFloat(value.value),
                      })
                    }
                    commandId={command.id}
                    title={command.title}
                    unit={command.unit}
                    min={command.min}
                    max={command.max}
                    currentValue={command.currentValue}
                  />
                </Grid>
              );
            case "int":
              return (
                <Grid item key={index}>
                  <ValueCommand
                    onSubmit={(value) =>
                      onIntCommand({
                        commandId: value.commandId,
                        value: parseInt(value.value, 10),
                      })
                    }
                    commandId={command.id}
                    title={command.title}
                    unit={command.unit}
                    currentValue={command.currentValue}
                  />
                </Grid>
              );
            case "pulseButtonCommand":
              return (
                <Grid item key={index}>
                  <ButtonCommand
                    onSubmit={({ value, commandId }) => {
                      onPulseCommand({ value, commandId });
                    }}
                    currentValue={command.currentValue}
                    commandValue={command.commandValue}
                    commandId={command.id}
                    title={command.title}
                    color={command.color}
                  />
                </Grid>
              );
            case "select":
              return (
                <Grid item key={index}>
                  <SelectCommand
                    onSubmit={({ value, commandId }) => {
                      onIntCommand({ value: parseInt(value, 10), commandId });
                    }}
                    currentValue={command.currentValue}
                    commandId={command.id}
                    title={command.title}
                    opts={command.opts}
                  />
                </Grid>
              );
            case "subtitle":
              return (
                <Grid xs={12} item key={index}>
                  <Typography variant="h6">{command.title}</Typography>
                </Grid>
              );
            case "divider":
              return (
                <Grid item xs={12} key={index}>
                  <Divider />
                </Grid>
              );
            default:
              return <div>not implemented</div>;
          }
        })}
      </Grid>
    </Paper>
  );
};

export default Screen;
