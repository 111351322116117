import React from "react";
import { Link } from "react-router-dom";

const Devices: React.FC = () => {
  return (
    <div>
      <Link to={"/device3"}>Elmarco Seagate</Link>
    </div>
  );
};

export default Devices;
