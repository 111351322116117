import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { AdapterLink } from "../AdapterLink";

export const mainListItems = (
  <div>
    <ListItem button component={AdapterLink} to={"/device3"}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Elmarco Seagate" />
    </ListItem>
  </div>
);

export const secondaryListItems = (
  <div>
    {/*<ListSubheader inset>Saved reports</ListSubheader>*/}
    {/*<ListItem button>*/}
    {/*  <ListItemIcon>*/}
    {/*    <AssignmentIcon />*/}
    {/*  </ListItemIcon>*/}
    {/*  <ListItemText primary="Current month" />*/}
    {/*</ListItem>*/}
    {/*<ListItem button>*/}
    {/*  <ListItemIcon>*/}
    {/*    <AssignmentIcon />*/}
    {/*  </ListItemIcon>*/}
    {/*  <ListItemText primary="Last quarter" />*/}
    {/*</ListItem>*/}
    {/*<ListItem button>*/}
    {/*  <ListItemIcon>*/}
    {/*    <AssignmentIcon />*/}
    {/*  </ListItemIcon>*/}
    {/*  <ListItemText primary="Year-end sale" />*/}
    {/*</ListItem>*/}
  </div>
);
