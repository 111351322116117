import { useMemo } from "react";
import { commandToValues } from "./utils";
import { Commands } from "../Screen";
import { DeviceStateQuery } from "../../__generated__/DeviceStateQuery";

export const useCommandWithValue = (
  commands: Commands[],
  data?: DeviceStateQuery
) => {
  return useMemo(() => {
    const parser = commandToValues(data);
    return commands.map<Commands>(parser);
  }, [data, commands]);
};
