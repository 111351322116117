import React, { useEffect, useMemo, useState } from "react";
import { Grid, GridProps, Snackbar } from "@material-ui/core";
import { cs } from "date-fns/locale";
import { format } from "date-fns";
import Screen, { Commands, ValueCommandType } from "../Screen";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import {
  deviceStateQuery,
  deviceUpdateSubscription,
  setFloatCommandMutation,
  setIntCommandMutation,
  setPulseCommandMutation,
} from "./queries";
import {
  SetFloatCommand,
  SetFloatCommandVariables,
} from "../../__generated__/SetFloatCommand";
import {
  SetIntCommand,
  SetIntCommandVariables,
} from "../../__generated__/SetIntCommand";
import {
  DeviceStateQuery,
  DeviceStateQueryVariables,
} from "../../__generated__/DeviceStateQuery";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import {
  DeviceUpdateSub,
  DeviceUpdateSubVariables,
} from "../../__generated__/DeviceUpdateSub";
import { useCommandWithValue } from "./useCommandWithValue";
import {
  SetPulseCommand,
  SetPulseCommandVariables,
} from "../../__generated__/SetPulseCommand";
import { makeScreen } from "./useScreen";

const DEVICE_COMMAND_ID = "device.command.device3";
const DEVICE_ID = "dev3";

type Severity = "success" | "error";

const gridItem: GridProps = {
  item: true,
  xs: 12,
  md: 6,
};

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const configCleanA: Commands[] = [
  {
    id: "REM_Provoz_SP_Clean_A_GS3_speed",
    title: "Frekvenční měnič ventilátor",
    type: "float",
    unit: "Hz",
    min: 0,
    max: 50,
  },
  {
    id: "REM_Provoz_SP_Clean_A_YV1_klapa",
    title: "Klapa oxiz",
    type: "float",
    unit: "%",
    min: 0,
    max: 100,
  },
  {
    id: "REM_Provoz_SP_Clean_A_YV2_klapa",
    title: "Klapa plazkat",
    type: "float",
    unit: "%",
    min: 0,
    max: 100,
  },
];

const configRegenA: Commands[] = [
  {
    id: "REM_Provoz_SP_Regen_A_GS3_speed",
    title: "Frekvenční měnič ventilátor",
    type: "float",
    unit: "Hz",
    min: 0,
    max: 50,
  },
  {
    id: "REM_Provoz_SP_Regen_A_YV1_klapa",
    title: "Klapa oxiz",
    type: "float",
    unit: "%",
    min: 0,
    max: 100,
  },
  {
    id: "REM_Provoz_SP_Regen_A_YV2_klapa",
    title: "Klapa plazkat",
    type: "float",
    unit: "%",
    min: 0,
    max: 100,
  },
];

const configCleanB: Commands[] = [
  {
    id: "REM_Provoz_SP_Clean_B_GS7_speed",
    title: "Frekvenční měnič ventilátor",
    type: "float",
    unit: "Hz",
    min: 0,
    max: 50,
  },
  {
    id: "REM_Provoz_SP_Clean_B_YV3_klapa",
    title: "Klapa oxiz",
    type: "float",
    unit: "%",
    min: 0,
    max: 100,
  },
  {
    id: "REM_Provoz_SP_Clean_B_YV4_klapa",
    title: "Klapa plazkat",
    type: "float",
    unit: "%",
    min: 0,
    max: 100,
  },
];

const configRegenB: Commands[] = [
  {
    id: "REM_Provoz_SP_Regen_B_GS7_speed",
    title: "Frekvenční měnič ventilátor",
    type: "float",
    unit: "Hz",
    min: 0,
    max: 50,
  },
  {
    id: "REM_Provoz_SP_Regen_B_YV3_klapa",
    title: "Klapa oxiz",
    type: "float",
    unit: "%",
    min: 0,
    max: 100,
  },
  {
    id: "REM_Provoz_SP_Regen_B_YV4_klapa",
    title: "Klapa plazkat",
    type: "float",
    unit: "%",
    min: 0,
    max: 100,
  },
];

// const ConfigInt: Commands[] = [
//   { type: "int", id: "REM_Reaktory_AB_SpiderCleaner" },
//   { type: "int", id: "REM_Reaktory_AB_Regen" },
// ];

const configDevice: Commands[] = [
  {
    type: "int",
    id: "REM_RegenStartTimeHour",
    title: "Regenerace start hodiny",
    unit: "h",
  },
  {
    type: "int",
    id: "REM_RegenStartTimeMin",
    title: "Regenerace start minuty",
    unit: "min",
  },
  {
    type: "int",
    id: "REM_RegenStopTimeHour",
    title: "Regenerace stop hodiny",
    unit: "h",
  },
  {
    type: "int",
    id: "REM_RegenStopTimeMin",
    title: "Regenerace stop minuty",
    unit: "min",
  },
  {
    type: "pulseButtonCommand",
    title: "Reset chyby FM",
    id: "REM_ResetFault_FM",
    commandValue: 1,
    color: "red",
  },
];

const configStateSelector: Commands[] = [
  {
    type: "select",
    id: "REM_Reaktory_AB_SpiderCleaner",
    opts: [
      { label: "Vypnuto", value: 0 },
      { label: "Čištění A", value: 1 },
      { label: "Čištění B", value: 2 },
      { label: "Čištění A + B", value: 3 },
      { label: "Čištění A + Regen B", value: 4 },
      { label: "Regen A + Čištění B", value: 5 },
    ],
  },
  {
    type: "select",
    id: "REM_Reaktory_AB_Regen",
    opts: [
      { label: "Vypnuto", value: 0 },
      { label: "Regen A", value: 6 },
      { label: "Regen B", value: 7 },
      { label: "Regen A + B", value: 8 },
    ],
  },
];

const configSettings: Commands[] = [
  {
    type: "select",
    id: "REMI_Status_ventily_VOC4",
    title: "Nastavení měřících míst pro VOC",
    opts: [
      { label: "Vypnuto", value: 0 },
      { label: "Výstup A", value: 1 },
      { label: "Výstup B", value: 2 },
      { label: "Výstup spider", value: 3 },
    ],
  },
  {
    type: "float",
    id: "REM_SP_temperace_kontejner",
    title: "Temperace kontejneru",
    unit: "C",
  },
];

export const Device3: React.FC = () => {
  const [{ visible, message, severity }, setNotification] = useState<{
    visible: boolean;
    message?: string;
    severity?: Severity;
  }>({
    visible: false,
  });
  const [setFloatMutation] = useMutation<
    SetFloatCommand,
    SetFloatCommandVariables
  >(setFloatCommandMutation);

  const [setIntMutation] = useMutation<SetIntCommand, SetIntCommandVariables>(
    setIntCommandMutation
  );

  const [setPulseMutation] = useMutation<
    SetPulseCommand,
    SetPulseCommandVariables
  >(setPulseCommandMutation);

  const { data, refetch } = useQuery<
    DeviceStateQuery,
    DeviceStateQueryVariables
  >(deviceStateQuery, { variables: { deviceId: DEVICE_ID } });

  const { data: subData } = useSubscription<
    DeviceUpdateSub,
    DeviceUpdateSubVariables
  >(deviceUpdateSubscription, { variables: { deviceId: DEVICE_ID } });

  const timestamp = subData?.deviceUpdates?.timestamp;

  useEffect(() => {
    refetch().then(() => {
      console.log("updated", timestamp);
    });
  }, [timestamp, refetch]);

  const onFloatCommandSet = ({ commandId, value }: ValueCommandType) => {
    setFloatMutation({
      variables: { input: { deviceId: DEVICE_COMMAND_ID, commandId, value } },
    })
      .then(() => {
        setNotification({
          visible: true,
          severity: "success",
          message: `Příkaz: ${commandId} hodnota: ${value} odeslán`,
        });
      })
      .catch((error) => {
        console.error(error);
        setNotification({
          visible: true,
          severity: "error",
          message: `Chyba: ${JSON.stringify(error)}`,
        });
      });
  };

  const onIntCommandSet = ({ value, commandId }: ValueCommandType) => {
    setIntMutation({
      variables: { input: { deviceId: DEVICE_COMMAND_ID, commandId, value } },
    })
      .then(() => {
        setNotification({
          visible: true,
          severity: "success",
          message: `Příkaz: ${commandId} hodnota: ${value} odeslán`,
        });
      })
      .catch((error) => {
        console.error(error);
        setNotification({
          visible: true,
          severity: "error",
          message: `Chyba: ${JSON.stringify(error)}`,
        });
      });
  };

  const onPulseCommandSet = ({ value, commandId }: ValueCommandType) => {
    setPulseMutation({
      variables: { input: { deviceId: DEVICE_COMMAND_ID, commandId, value } },
    })
      .then(() => {
        setNotification({
          visible: true,
          severity: "success",
          message: `Příkaz: ${commandId} hodnota: ${value} odeslán`,
        });
      })
      .catch((error) => {
        console.error(error);
        setNotification({
          visible: true,
          severity: "error",
          message: `Chyba: ${JSON.stringify(error)}`,
        });
      });
  };

  const cleanA = useCommandWithValue(configCleanA, data);
  const regenA = useCommandWithValue(configRegenA, data);
  const cleanB = useCommandWithValue(configCleanB, data);
  const regenB = useCommandWithValue(configRegenB, data);
  const configScreen = useCommandWithValue(configDevice, data);
  const stateSelector = useCommandWithValue(configStateSelector, data);
  // const intComWithValue = useCommandWithValue(ConfigInt, data);
  const stateConfigSettings = useCommandWithValue(configSettings, data);

  const formTimestamp = useMemo(() => {
    const date = new Date(timestamp);

    return timestamp
      ? format(date, "yyyy-MM-dd HH:mm:ss", { locale: cs })
      : undefined;
  }, [timestamp]);

  const handleClose = () => {
    setNotification({ visible: false, message: undefined });
  };

  return (
    <>
      <Snackbar open={visible} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      <Grid container spacing={3}>
        <Grid {...gridItem}>
          <Screen
            screenTitle="Volba režimu zařízení"
            screenTimestamp={formTimestamp}
            onFloatCommand={onFloatCommandSet}
            onIntCommand={onIntCommandSet}
            onPulseCommand={onPulseCommandSet}
            commandQueue={DEVICE_COMMAND_ID}
            commands={stateSelector}
          />
        </Grid>
        <Grid {...gridItem}>
          <Screen
            screenTitle="Nastavení zařízení"
            screenTimestamp={formTimestamp}
            onFloatCommand={onFloatCommandSet}
            onIntCommand={onIntCommandSet}
            onPulseCommand={onPulseCommandSet}
            commandQueue={DEVICE_COMMAND_ID}
            commands={configScreen}
          />
        </Grid>
        <Grid {...gridItem}>
          <Screen
            screenTitle="Čištění A"
            screenTimestamp={formTimestamp}
            onFloatCommand={onFloatCommandSet}
            onIntCommand={onIntCommandSet}
            onPulseCommand={onPulseCommandSet}
            commandQueue={DEVICE_COMMAND_ID}
            commands={cleanA}
          />
        </Grid>
        <Grid {...gridItem}>
          <Screen
            screenTitle="Regenerace A"
            screenTimestamp={formTimestamp}
            onFloatCommand={onFloatCommandSet}
            onIntCommand={onIntCommandSet}
            onPulseCommand={onPulseCommandSet}
            commandQueue={DEVICE_COMMAND_ID}
            commands={regenA}
          />
        </Grid>
        <Grid {...gridItem}>
          <Screen
            screenTitle="Čištění B"
            screenTimestamp={formTimestamp}
            onFloatCommand={onFloatCommandSet}
            onIntCommand={onIntCommandSet}
            onPulseCommand={onPulseCommandSet}
            commandQueue={DEVICE_COMMAND_ID}
            commands={cleanB}
          />
        </Grid>
        <Grid {...gridItem}>
          <Screen
            screenTitle="Regenerace B"
            screenTimestamp={formTimestamp}
            onFloatCommand={onFloatCommandSet}
            onIntCommand={onIntCommandSet}
            onPulseCommand={onPulseCommandSet}
            commandQueue={DEVICE_COMMAND_ID}
            commands={regenB}
          />
        </Grid>
        {/*<Grid {...gridItem}>*/}
        {/*  <Screen*/}
        {/*    screenTitle="Reaktory AB"*/}
        {/*    screenTimestamp={formTimestamp}*/}
        {/*    onFloatCommand={onFloatCommandSet}*/}
        {/*    onIntCommand={onIntCommandSet}*/}
        {/*    onPulseCommand={onPulseCommandSet}*/}
        {/*    commandQueue={DEVICE_COMMAND_ID}*/}
        {/*    commands={intComWithValue}*/}
        {/*  />*/}
        {/*</Grid>*/}
        <Grid {...gridItem}>
          <Screen
            screenTitle="Nastavení"
            screenTimestamp={formTimestamp}
            onFloatCommand={onFloatCommandSet}
            onIntCommand={onIntCommandSet}
            onPulseCommand={onPulseCommandSet}
            commandQueue={DEVICE_COMMAND_ID}
            commands={stateConfigSettings}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Device3;
