import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";

const WithAuthZero: React.FC = ({ children }) => {
  const history = useHistory();
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

  const onRedirectCallback = (appState: any) => {
    history.replace(
      (appState && appState.returnTo) || window.location.pathname
    );
  };
  return (
    <Auth0Provider
      domain={domain || "ecocoal.eu.auth0.com"}
      clientId={clientId || "Yej57jQ5imR7gQO1zjXrKNrdGfsLOsHa"}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience="ecocoal-graphql"
      scope="openid profile email"
    >
      {children}
    </Auth0Provider>
  );
};

export default WithAuthZero;
