import React from "react";
import { Form, Field } from "react-final-form";
import {
  InputAdornment,
  TextField,
  Button,
  ButtonGroup,
  Tooltip,
  Theme,
  createStyles,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      width: "30em",
    },
  })
);

interface IValueCommand {
  // value: string because some shitty thing in mui
  onSubmit: (value: { commandId: string; value: string }) => void;
  currentValue?: string | number;
  unit?: string;
  min?: number;
  max?: number;
  commandId: string;
  title?: string;
}

const formatOutput = (input: { value?: number | string; unit?: string }) => {
  if (input.unit !== undefined) {
    return input.value !== undefined
      ? `(${input.value}) ${input.unit}`
      : `(...) ${input.unit}`;
  }
  return input.value !== undefined ? `(${input.value})` : "(...)";
};

type InitialValues = {
  float?: number;
};

const ValueCommand: React.FC<IValueCommand> = ({
  currentValue,
  unit,
  commandId,
  onSubmit,
  title,
  min,
  max,
}) => {
  const initialValues: InitialValues = {};
  const classes = useStyles();
  return (
    <Form
      initialValues={initialValues}
      onSubmit={(values, form) => {
        onSubmit({ commandId, value: values.float });
        setTimeout(form.reset);
      }}
      render={({ handleSubmit, submitting, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Field
              name="float"
              render={({ input, meta }) => (
                <ButtonGroup disableElevation>
                  <Tooltip title={commandId}>
                    <TextField
                      className={classes.textField}
                      {...input}
                      {...meta}
                      size="small"
                      type="number"
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        min,
                        max,
                      }}
                      disabled={submitting}
                      label={title || commandId}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {formatOutput({ value: currentValue, unit })}
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                    />
                  </Tooltip>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={submitting || pristine}
                  >
                    Nastav
                  </Button>
                </ButtonGroup>
              )}
            />
          </form>
        );
      }}
    />
  );
};

export default ValueCommand;
