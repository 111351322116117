import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: theme.palette.primary.contrastText,
    },
  })
);

const LogoutButton = () => {
  const { logout } = useAuth0();
  const classes = useStyles();

  return (
    <Button className={classes.title} onClick={() => logout()}>
      Log Out
    </Button>
  );
};

export default LogoutButton;
