import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import Devices from "./components/Devices";
import Device3 from "./components/Device3";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { ThemeProvider } from "@material-ui/core";
import AppLayout from "./components/AppLayout";
import { theme } from "./theme";
import WithApollo from "./components/WithApollo";

function App() {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState<string>();

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      setToken(token);
    });
  }, [getAccessTokenSilently]);

  return (
    <WithApollo token={token}>
      <ThemeProvider theme={theme}>
        <AppLayout appTitle={"Ecocoal monitoring"}>
          <Switch>
            <Route
              path="/"
              component={withAuthenticationRequired(Devices)}
              exact
            />
            <Route
              path="/device3"
              component={withAuthenticationRequired(Device3)}
            />
          </Switch>
        </AppLayout>
      </ThemeProvider>
    </WithApollo>
  );
}

export default App;
