import React, { Reducer, useEffect, useReducer } from "react";
import { Button } from "@material-ui/core";

const DISABLED_DURATION = 3000;

interface IButtonCommand {
  title?: string;
  commandId: string;
  commandValue: number;
  currentValue?: number;
  color?: "red" | "green";
  onSubmit: (value: { commandId: string; value: number }) => void;
}

type ButtonCommandState = {
  disabled: boolean;
};

const initialState: ButtonCommandState = {
  disabled: false,
};

type ButtonActions =
  | { type: "[UI] buttonDisable" }
  | { type: "[UI] buttonEnable" };

const ButtonCommand: React.FC<IButtonCommand> = ({
  title,
  commandId,
  commandValue,
  onSubmit,
  currentValue,
  color,
}) => {
  const [{ disabled }, dispatch] = useReducer<
    Reducer<ButtonCommandState, ButtonActions>
  >((state, action) => {
    switch (action.type) {
      case "[UI] buttonDisable":
        return { ...state, disabled: true };
      case "[UI] buttonEnable":
        return { ...state, disabled: false };
      default:
        return state;
    }
  }, initialState);

  useEffect(() => {
    if (disabled) {
      let timeout = setTimeout(() => {
        dispatch({ type: "[UI] buttonEnable" });
      }, DISABLED_DURATION);

      return () => clearTimeout(timeout);
    }
  }, [disabled]);

  return (
    <Button
      color={color === "red" ? "secondary" : "primary"}
      variant="contained"
      disabled={disabled}
      onClick={() => {
        onSubmit({ value: commandValue, commandId });
        dispatch({ type: "[UI] buttonDisable" });
      }}
    >
      {`${title || commandId}`}
    </Button>
  );
};
export default ButtonCommand;
